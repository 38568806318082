// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {
  VCol,
  VRow,
} from 'vuetify/lib'
import "@mdi/font/css/materialdesignicons.min.css"

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow,
  },
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#F39503',
        secondary: '#545454',
        accent: '#343434',
      },
      dark: {},
    },
    icons: {
        iconFont: "md",
    },
  },
})
