<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: 'Vozový park na míru - správa firemních flotil | AutoCásek s.r.o.',
      htmlAttrs: { lang: 'cs' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Operativní pronájem aut a správa vozových parků pro firmy. Zprostředkování pojištění a prodeje vozidel. Poskytování zápůjček náhradních vozidel v případě pojistných událostí.' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
