<template>
    <cookie-law theme="dark-lime" buttonText="Souhlasím" storageType="cookies" @accept="AcceptGa()">
        <div slot="message">
            Tyto webové stránky používají k poskytování svých služeb soubory Cookies. Používáním těchto webových stránek souhlasíte s použitím souborů Cookies.
        </div>
    </cookie-law>
</template>
 
<script>
    import CookieLaw from 'vue-cookie-law'
    import Vue from 'vue'
    import VueGtag from "vue-gtag";

    export default {
        components: { CookieLaw },
        methods: {
            AcceptGa() {
                Vue.use(VueGtag, {
                    config: { id: "G-LKFECSFJDP" }
                });
            },
        }
      
    }
</script>
<style>
    .Cookie__button {
        background-color: #F39503 !important;
    }
</style>